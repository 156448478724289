<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron plantillas de artículos"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'two-line': true }"
      :loading="loadingData"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      :page-loaded="pageLoaded"
      pk-name="plantilla_articulo.idplantilla_articulo"
      @click-item="clickListItem"
    >
      <template
        #listItem="slotProps"
      >
        <plantilla-articulo-list-item
          :item="slotProps.item"
        />
      </template>
    </b10-list>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './PlantillaArticuloListData'
import PlantillaArticuloListItem from './components/PlantillaArticuloListItem'
import { get } from 'vuex-pathify'
import { NextRouteNavigate } from '@/router'

const pageStoreName = 'pagesOfflinePlantillaArticuloList'

export default {
  components: {
    PlantillaArticuloListItem,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
    nextRoute: get(`${pageStoreName}/nextRoute`),
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Plantillas de artículos'
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectPlantillaArticulo(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        await this.reloadItemsBase(
          await Data.selectPlantillaArticuloRows(this, dirty.modified), dirty, 'plantilla_articulo.idplantilla_articulo'
        )
      } finally {
        this.loadingData = false
      }
    },
    async clickListItem (item) {
      if (this.nextRoute && _.has(this.nextRoute.params, 'idplantilla_articulo')) {
        await this.setStoreProperty('nextRoute@params.idplantilla_articulo', item.data.plantilla_articulo.idplantilla_articulo)
        NextRouteNavigate(this.nextRoute)
      }
    }
  }
}
</script>
